<template>
  <component
    :is="compontentType"
    :type="!link ? type : false"
    :to="redirectionLink"
    class="no-outline button-full block bg-cl-black brdr-none px20 py10 ripple weight-600 brdr-50px cl-white fs-medium-small"
    :class="{ 'no-underline pointer align-center border-box': link, 'disabled': disabled, 'button-disabled': disabled }"
    data-testid="subscribeSubmit"
    :disabled="disabled"
  >
    <slot>
      <slot>{{ title }}</slot>
    </slot>
  </component>
</template>

<script>
import focusClean from 'theme/components/theme/directives/focusClean'
export default {
  name: 'ButtonFull',
  directives: { focusClean },
  props: {
    title: {
      type: String,
      default: 'Button'
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    link: {
      type: [Object, String],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    compontentType () {
      return this.link ? 'router-link' : 'button'
    },
    redirectionLink () {
      return this.link ? this.localizedRoute(this.link) : null
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-full {
    transition: all 0.3s;
    &:hover {
      background-color: white;
      color: black;
      box-shadow: inset 0px 0px 0px 3px black;
      font-weight: 600;
    }
  }
  .disabled {
    background-color: gray
  }
</style>

<style lang="scss">
  // @media screen and (min-width: 768px) {
  //   .button-full {
  //     min-width: 250px;
  //   }
  // }
</style>
