var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reset-password"},[_c('h2',{staticClass:"reset-password__heading"},[_vm._v("\n    "+_vm._s(_vm.$t('Reset password'))+"\n  ")]),_vm._v(" "),_c('form',{staticClass:"reset-password__form"},[_c('div',{staticClass:"reset-password__row"},[_c('base-input',{staticClass:"mb10",attrs:{"type":"password","name":"password","placeholder":_vm.$t('Password *'),"validations":[
          {
            condition: !_vm.$v.password.required && _vm.$v.password.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.password.minLength && _vm.$v.password.$error,
            text: _vm.$t('Password must have at least 6 letters.')
          }
        ]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_vm._v(" "),_c('div',{staticClass:"reset-password__row"},[_c('base-input',{staticClass:"mb10",attrs:{"type":"password","name":"password-confirm","placeholder":_vm.$t('Repeat password *'),"validations":[
          {
            condition: !_vm.$v.rPassword.required && _vm.$v.rPassword.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.rPassword.sameAsPassword && _vm.$v.rPassword.$error,
            text: _vm.$t('Passwords must be identical.')
          }
        ]},on:{"blur":function($event){return _vm.$v.password.$touch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }!_vm.$v.$invalid ? _vm.resetPassword() : null}},model:{value:(_vm.rPassword),callback:function ($$v) {_vm.rPassword=$$v},expression:"rPassword"}})],1),_vm._v(" "),_c('div',{staticClass:"reset-password__row"},[_c('ButtonFull',{attrs:{"theme":"dark","disabled":_vm.$v.$invalid},nativeOn:{"click":function($event){return _vm.resetPassword($event)}}},[_vm._v("\n        "+_vm._s(_vm.$t('Reset password'))+"\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }