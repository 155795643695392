<template>
  <div class="relative base-input">
    <div class="relative">
      <input
        class="py10 px10 w-100 border-box bg-cl-white brdr-1"
        :class="{ pr30: type === 'password', empty: value === '' }"
        :type="type === 'password' ? passType : type"
        :name="name"
        :autocomplete="autocomplete"
        :value="value"
        :autofocus="autofocus"
        :ref="name"
        :id="placeholder + 'id'"
        @input="$emit('input', $event.target.value)"
        @blur="$emit('blur')"
        @keyup.enter="$emit('keyup.enter', $event.target.value)"
        @keyup="$emit('keyup', $event)"
        @wheel="disableInputScroll"
        :disabled="disabled"
      >
      <label :for="placeholder + 'id'">{{ placeholder }}</label>
    </div>
    <button
      v-if="iconActive"
      type="button"
      class="
        icon absolute brdr-none no-outline
        p0 bg-cl-transparent cl-brdr-transparent cl-black pointer
      "
      @click="togglePassType()"
      :aria-label="$t('Toggle password visibility')"
      :title="$t('Toggle password visibility')"
    >
      <i class="fs-medium" :class="icon" />
    </button>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from './ValidationMessages.vue'

export default {
  name: 'BaseInput',
  components: {
    ValidationMessages
  },
  data () {
    return {
      passType: 'password',
      iconActive: false,
      icon: 'icon-eye_closed'
    }
  },
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      defalult: false
    }
  },
  methods: {
    disableInputScroll (e) {
      e.target.blur()
    },
    isError () {
      let hasError = false;
      this.validations.forEach(val => {
        if (val.condition) {
          hasError = true;
        }
      });
      return hasError;
    },
    togglePassType () {
      if (this.passType === 'password') {
        this.passType = 'text'
        this.icon = 'icon-eye'
      } else {
        this.passType = 'password'
        this.icon = 'icon-eye_closed'
      }
    },
    // setFocus sets focus on a field which has a value of 'ref' tag equal to fieldName
    setFocus (fieldName) {
      if (this.name === fieldName) {
        this.$refs[this.name].focus()
      }
    }
  },
  created () {
    if (this.type === 'password') {
      this.iconActive = true
    }
  },
  mounted () {
    if (this.focus) {
      this.$refs[this.name].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  // @media (max-width: 767px) {
  //   height: 40px;
  // }
  position: relative;
  border: 1px solid;
  border-color: #D6D8DE;
  border-radius: 0;
  // background-color: #faf7f2;
  // border: none;
  font-family: 'Poppins';
  &:hover,
  &:focus {
    outline: none;
    border-color: #000;
  }

  &:disabled,
  &:disabled + label {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  }
}
.coupon-field {
  height: 48px;
    @media (max-width: 767px) {
    height: 36px;
  }
}
label {
  color:#707070;
  position: absolute;
  pointer-events: none;
  user-select: none;
  top: 12px;
  left: 10px;
  transition:0.2s ease all;
  -moz-transition:0.2s ease all;
  -webkit-transition:0.2s ease all;
  font-size: 0.8em;
  white-space: nowrap;
}
input:focus ~ label, input:not(.empty) ~ label {
  top: -10px!important;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
}
.icon {
  bottom: 8px;
  right: 20px;

}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>
